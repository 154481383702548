<template>
  <div>
    <section>
      <div class="bannerimg cover-image bg-background3">
        <div class="header-text mb-0">
          <div class="container">
            <div class="text-center text-white">
              <h1 class="">Kariyer</h1>
            </div>
          </div>
        </div>
      </div>
      <Breadcrumb
        title="Kariyer"
        :array="[
          { islink: true, url: '/', text: 'Ana Sayfa' },
          { islink: false, url: '', text: 'Kariyer' },
        ]"
      />
    </section>
    <!--section-->
    <section class="sptb">
      <div class="container">
        <div class="text-justify">
          <h1 class="mb-4">Terapi Vitrini</h1>

          <p class="leading-normal">
            Terapi Vitrini, terapi desteği almak isteyenlere uygun terapi
            hizmetini sağlayan, terapi hizmetini güvenilir bir yapı içinde ve
            uygun ekonomik koşullarda sunan, terapi hizmetlerine ihtiyaç
            duyanlar ile terapistleri bir araya getirmek üzere tasarlanmış,
            erişimi ve kullanımı kolay olan bir platformdur.
          </p>
        </div>
      </div>
    </section>
    <!--/section-->
  </div>
</template>
<script>
import Breadcrumb from "../components/Breadcrumb.vue";
export default {
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    document.title = "Kariyer - Terapi Vitrini";
    const meta = document.getElementsByTagName("meta");
    meta.keywords.content =
      "Kariyer - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz";
    meta.author.content = value[0].fullname;
    meta.description.content =
      "Bize ulaşmak için formda uygun başlıkları girin";
  },
  data() {
    return {};
  },
  components: { Breadcrumb },
  methods: {
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
  },
  mounted() {
    this.backToTop();
  },
};
</script>